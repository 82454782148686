.order {
  .logo {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    &.mtn {
      background: #ffcc00;
      img {
        width: 43px;
        height: 18px;
      }
    }
    &.airtel {
      background: #ee1c25;
      img {
        width: 29px;
        height: 31px;
      }
    }
    &.glo {
      background: linear-gradient(180deg, #13910b 0%, #0b3d05 100%);
      img {
        width: 32px;
        height: 28px;
      }
    }
    &._9mobile {
      background: #006d52;
      img {
        width: 20px;
        height: 35px;
      }
    }
    &.dstv {
      background: #026eb6;
      img {
        width: 43px;
      }
    }
    &.gotv {
      background: #f1f529;
      img {
        width: 43px;
      }
    }
    &.startimes {
      background: #fff;
      img {
        width: 43px;
      }
    }
    &.skysenx-internet {
      background: #fedbff;
    }
    &.distributor {
      img {
        width: 100%;
      }
    }
    &.shawarma {
      img {
        width: 43px;
        border-radius: 43px;
      }
    }
  }
}
