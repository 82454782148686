.dashboard {
  .charts {
    .row {
      display: flex;
      flex-direction: column;
      .col {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .col-2 {
          flex-basis: 49%;
        }
      }
    }
  }
}
