.charts {
    .daily-report {
        table {
            th,td {
                padding: 8px 10px;
            }
        }
        .controls {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            margin-top: 10px;
            .fixed-days {
                flex-grow: 1;
                max-width: 280px;
            }
            h4 {
                margin-right: 20px;
                line-height: 30px;
            }
        }
    }
}