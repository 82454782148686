.order {
  .marketplace {
    table.items {
      width: 100%;
      padding: 10px;
      border: 1px solid #b2b7c2;
      border-radius: 12px;
      td:first-child {
        color: #b2b7c2;
      }
      td:last-child {
        text-align: right;
        color: #181b36;
      }
    }
  }
}
