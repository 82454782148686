.pagination {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    padding: 15px 10px;
    border-bottom: 0.3px solid #d9d9d9;
    margin-bottom: 10px;
    align-items: center;
    h4 {
        flex-grow: 1;
    }
    ul {
        display: flex;
        flex-direction: row;
        list-style: none;
        align-items: center;
        li {
            img {
                height: 25px;
                width: 25px;
                display: block;
                margin: 0 5px;
                cursor: pointer;
            }
        }
        select {
            padding: 5px;
        }
        select:focus {
            outline: none;
        }
    }
}
