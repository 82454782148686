:root {
    --bg-dark: #19CD00;
    --bg-darker: #108100;
    --text-light: #fff;
    --bg-main: #F2F6FF;
}

* {
    margin: 0;
}

body {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    color: #4c4c4c;
}

h2 {
    font-weight: 400;
}
h3 {
    font-weight: 400;
}
h4 {
    font-weight: 500;
}