header {
    .search {
        padding: 17.5px;
        position: relative;
        background-color: #fff;
        box-sizing: border-box;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        select {
            border: 1px solid #F3F3F3;
            border-right: 0;
            height: 35px;
            padding: 5px;
            background-color: var(--bg-main);
            box-sizing: border-box;
        }
        select:first-child {
            width: 250px;
            border-radius: 10px 0 0 10px;
        }
        select:last-child {
            width: 100%;
            border-radius: 0 10px 10px 0;
        }
        input {
            border: 1px solid #F3F3F3;
            border-left: 0;
            height: 35px;
            padding: 5px 30px 5px 5px;
            box-sizing: border-box;
            border-radius: 0 10px 10px 0;
            width: 100%;
            background-color: var(--bg-main);
        }
        input:focus {
            outline: none;
        }
        img {
            position: absolute;
            height: 20px;
            top: 25px;
            right: 30px;
        }
    }
}