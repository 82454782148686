.charts {
    .type-metrics {
        
        .controls {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            .fixed-days {
                flex-grow: 1;
                max-width: 280px;
            }
            h4 {
                margin-right: 20px;
            }
        }
    }
}