.charts {
  .fixed-days {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .fixed-day {
      border: 0;
      background: #f6f7fa;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      width: 65px;
      font-weight: 500;
      font-size: 13px;
      line-height: 30px;
      letter-spacing: 0.25px;
      text-align: center;
      color: #55576b;
      cursor: pointer;
      &.active {
        background: var(--bg-dark);
        color: #fff;
      }
    }
  }
}
