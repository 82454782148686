section.auth {
    color: #262626;
    h3 {
        font-size: 16px;
        font-weight: normal;
        font-size: 28px;
        margin: 20px 0;
        text-align: center;
    }
    div.actions {
        margin: 20px 0 10px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        button {
            text-transform: capitalize;
            font-size: 15px;
            line-height: 35px;
            border: none;
            color: var(--text-light);
            font-weight: 400;
            cursor: pointer;
            width: 100%;
            max-width: 220px;
            border-radius: 5px;
            background-color: var(--bg-dark);
            box-shadow: 0px 10px 30px rgba(0, 80, 133, 0.15);
        }
        button:focus {
            outline: none;
        }
    }
    .extras {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        a {
            color: #37ac83;
            text-decoration: none;
            font-size: 17px;
        }
    }
    div.signin {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button {
            text-transform: capitalize;
            font-size: 15px;
            line-height: 35px;
            color: var(--bg-dark);
            font-weight: 400;
            cursor: pointer;
            width: 100%;
            max-width: 220px;
            border-radius: 5px;
            background-color: transparent;
            border: 1px solid var(--bg-dark);
        }
        button:focus {
            outline: none;
        }
        p {
            font-size: 18px;
            line-height: 40px;
        }
    }
    div.signup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button {
            text-transform: capitalize;
            font-size: 15px;
            line-height: 35px;
            color: var(--bg-dark);
            font-weight: 400;
            cursor: pointer;
            width: 100%;
            max-width: 220px;
            border-radius: 5px;
            background-color: transparent;
            border: 1px solid var(--bg-dark);
        }
        button:focus {
            outline: none;
        }
        p {
            font-size: 18px;
            line-height: 40px;
        }
    }
    form {
        label {
            color: inherit;
        }
        input {
            border-color: #707070;
            background-color: transparent;
        }
    }
}