.flag {
    line-height: 30px;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;
    &.error {
        color: #721c24;
        background-color: #f8d7da;
        border: 1px solid #f5c6cb;
    }
    &.success {
        color: #2d673a;
        background-color: #d5eddb;
        border: 1px solid #c4e6cc;
    }
}