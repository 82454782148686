.pricing {
    .create {
        padding: 0 20px;
        margin: 0 auto;
        .triggers {
            padding: 0 20px 20px 20px;
            display: flex;
            flex-direction: row;
            margin: 0 auto;
            max-width: 360px;
            button {
                border: 0;
                background-color: #fff;
                line-height: 40px;
                border-radius: 5px;
                font-size: 16px;
                text-transform: uppercase;
                cursor: pointer;
            }
            button:first-child {
                flex-grow: 1;
                background-color: #32C832;
                color: #fff;
            }
            button:last-child {
                flex-basis: 120px;
                margin-left: 20px;
                border: 1px solid #FC7900;
                color: #FC7900;
            }
        }
    }
}
