.processor-settings {
    .items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .processor-setting {
            width: 300px;
            margin-right: 20px;
            button {
                border: 0;
                background-color: #3B7F22;
                padding: 10px 20px;
                border-radius: 5px;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}