.default-template {
    >.menu {
        background-color: var(--bg-darker);
        display: block;
        transition: flex-basis 300ms ease-in-out;
        flex-basis: 0;
        overflow: hidden;
        .logo {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;

            h1 {
                color: #fff;
                font-weight: 500;
                font-size: 20px;
                margin-top: 10px;
            }

            img {
                height: 40px;
            }
        }
        .space {
            height: 80px;
        }
        ul {
            list-style: none;
            padding: 0;
            li.item {
                align-items: center;
                color: #fff;
                line-height: 44px;
                box-sizing: border-box;
                cursor: pointer;
                transition: background-color 300ms ease-in-out 1ms;
                padding: 6px;
                padding-left: 40px;
                font-family: "DM Sans Bold";
                font-weight: 700;
                opacity: 0.6;
                span {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    b {
                        flex-basis: 130px;
                    }
                }
                &.active {
                    background: linear-gradient(to right, var(--bg-darker), #14a600, #14a600);
                    opacity: 1;
                }
                img:first-child {
                    height: 24px;
                    width: 24px;
                    margin-right: 15px;
                }
                ul {
                    height: auto;
                    max-height: 0;
                    transition: max-height 300ms ease-in-out 1ms;
                    overflow: hidden;
                    padding-left: 40px;
                    li {
                        a {
                            display: block;
                            line-height: 20px;
                            font-size: 14px;
                            padding: 10px;
                            color: #fff;
                            text-decoration: none;
                        }
                        opacity: 0.5;
                        &.active {
                            opacity: 1;
                        }
                    }
                }
                &.active ul {
                    max-height: 600px;
                }
            }
        }
        &.active {
            flex-basis: 250px;
            ul {
                li.item {
                    padding-left: 20px;
                }
            }
        }
    }
    @media (min-width: 700px) {
        .menu {
            flex-basis: 250px;
            &.active {
                ul {
                    li.item {
                        padding-left: 40px;
                    }
                }
            }
        }
    }
}